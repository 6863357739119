import { TTagsResponse } from '@dashboard/models/tag.model';
import { Injectable } from '@angular/core';
import { EnvVarsService } from 'src/app/env-vars.service';
import { CommonApiService } from '@core/api/common.api.service';
import { map, Observable } from 'rxjs';
import { Moment } from 'moment';
import {
  ApplyTimezoneToLocalDate,
  TimeZoneFormat,
} from '@core/constants/time.defaults';

@Injectable({
  providedIn: 'root',
})
export class NaviqoreDataApiService {
  private API_URL = this.environment.getEnvKey('EXPORTS_API_URL');
  private namespace = 'data';
  private url = `${this.API_URL}/${this.namespace}`;
  constructor(
    private common: CommonApiService,
    private environment: EnvVarsService
  ) {}

  public getExportGraphApiData(
    vesselId: string,
    obj: ExportGraphRequest,
    timezoneHours: number = null
  ): Observable<ExportDataResponse<number>> {
    return this.common.post(`${this.url}/${vesselId}`, obj).pipe(
      map((res: ExportDataResponse<string>) => {
        const UpdRes: ExportDataResponse<number> = {
          data: res.data,
          prediction: res.prediction,
          timestamps: [],
          detail: res.detail,
        };

        if (res.timestamps) {
          //Apply timzone to timestamp (add or remove hours to our gmt time)
          console.log('incoming timestamos to utc', {
            timezoneHours,
            timestamps: res.timestamps,
          });
          let d: Moment[] = [];
          if (timezoneHours !== null && timezoneHours !== undefined) {
            d = res.timestamps
              .map((d) => TimeZoneFormat(d))
              .map((d) => ApplyTimezoneToLocalDate(d, timezoneHours).moment());
          } else {
            d = res.timestamps.map((d) => TimeZoneFormat(d));
          }

          UpdRes['timestamps'] = d.map((d) => d.valueOf());
        }
        return UpdRes;
      })
    );
  }
}

export type TagHasDataResponse<T = number> = {
  timestamps: T[];
  data: TTagsResponse[];
  prediction: TTagsResponse[];
};

export type ExportDataResponse<T = number> = TagHasDataResponse<T> &
  ExportNoDataFound;

// type ExportNoDataFound = []
type ExportNoDataFound = {
  detail: string;
};

export type ExportGraphRequest = {
  start: string;
  end: string;
  aggregation?: number;
  axisLimits?: boolean;
  variables: TSelectedApiVariable[];
  filters: TFilteredApiVariable[];
  includePrediction?: boolean;
};

export type ApiVariable = {
  tagId: string | number;
};
export type TSelectedApiVariable = {
  transformation?: AvailableTransformations;
} & ApiVariable;

export type TFilteredApiVariable = TagFilters & ApiVariable;

export type AvailableTransformations =
  | UnitTransformation
  | AverageTransformation
  | FormattedTransformation;

export type TagFilters =
  | AvailableFilter
  | MinFilter
  | MaxFilter
  | ThresholdFilter
  | ValueFilter;

export type UnitTransformation = {
  type: 'UNIT';
  unit: string;
};
export type AverageTransformation = {
  type: 'AVERAGE';
  value?: number;
};

export type FormattedTransformation = {
  type: 'FORMATTED';
};

export type AvailableFilter = {
  config: { type: 'available'; value: boolean };
  offset: BeforeAfter;
};

export type BeforeAfter = { before: number; after: number };

export type MinFilter = {
  config: { type: 'min'; value: number };
  offset: BeforeAfter;
};
export type MaxFilter = {
  config: { type: 'max'; value: number };
  offset: BeforeAfter;
};

export type ThresholdFilter = {
  config: {
    type: 'threshold';
    value: number;
    window: BeforeAfter;
  };
  offset: BeforeAfter;
};

export type ValueFilter = {
  config: {
    type: 'value';
    value: string | number;
  };
};
