import { Injectable } from '@angular/core';

export interface IEnv {
  IS_COMMERCIAL: boolean;
  production: boolean;
  SENTRY_ID_: string;
  SENTRY_ENV: string;
  SENTRY_RELEASE: string;
  SHOW_WIND_ASSISTANCE: boolean;
  ROUTE_EXPIRMENTS: boolean;
  PUMP_VFD: boolean;
  SOCKET_ENDPOINT: string;
  EXPORTS_API_URL: string;
  WEATHER_API_URL: string;
  API_URL: string;
  MAP_URL: string;
  CONFIG_URL: string;
  WIKI_URL: string;
  ROUTE_OPT_URL: string;
  OML_URL: string;
  SHIP3D_URL: string;
  VESSEL_OFFLINE_TIMEOUT_HOURS: number;
  NEO4J: {
    serverUrl: string;
    serverUser: string;
    serverPassword: string;
  };
  reportingRequestsDemoData: {
    latest: boolean;
    common: boolean;
  };
  logos: {
    loginLogo: string;
    loginPageWidth: number;
    menuLogo: string;
    menuPageWidth: number;
    menuFooterLogo: string;
    menuFooterLogoWidth: number;
  };
  companyName: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnvVarsService {
  public getEnvKey<T extends keyof IEnv>(key: T): IEnv[T] {
    const env = window.ENV[key];
    if (env === null || env === undefined)
      throw new Error(`Env variable ${key} not found`);
    return window.ENV[key];
  }
}
